.App {
  text-align: center;
  font-family: monospace;
  background-color: #631F1F;
  width: auto;
  height: auto;
  align-self: center;
  display: flex;
  flex-direction: column;  
  height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.path-line {
  stroke-width: 0.15em;
}

.portal-icon {
  fill: purple;
  stroke: black;
  stroke-width: 0.4em;
}

.portal-text {
  fill: white;
  font-size: 36px;
  font-family: monospace;
  pointer-events: none;
  user-select: none;
}

.portal-text.selected {
  fill: yellow;
  font-weight: bold;
}

.popover-header {
  font-family: monospace;
  font-weight: bold;
  color: black;
}

.popover-body {
  font-family: monospace;
  font-size: 12px;
  text-align: center;
}

.map-grid-line {
  stroke: black;
  stroke-width: 0.05em;
}

.map-grid-text {
  font-family: monospace;
  font-size: 0.4em;
  fill: black;
  font-weight: bold;
  pointer-events: none;
  user-select: none;
}
